.promo {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &__title {
        margin-bottom: 30px; }
    h4 {
        font-size: 20px;
        color: #ffffff;
        font-weight: 300;
        letter-spacing: 1px; }
    .social-links {
        position: absolute;
        bottom: 50px;
        left: 50px;
        a {
            color: #ffffff;
            &:after {
                border-bottom: 1px solid #ffffff; } } }
    .play-video {
        position: absolute;
        bottom: 25px;
        right: 25px;
        padding: 25px;
        svg {
            stroke: #ffffff;
            path {
                fill: transparent;
                transition: 0.2s ease; } }
        &:hover {
            svg {
                path {
                    fill: #ffffff; } } } } }

.mask-svg-video {
    max-width: 750px;
    width: 100%;
    max-height: 65px;
    margin: -40px auto 80px; }

#mask-text {
    font-size: 79px;
    text-transform: uppercase;
    font-family: Baskerville;
    font-weight: 700;
    text-align: center; }

.mask-svg {
    width: 100%; }

.svg-clipped-text {
    -webkit-clip-path: url(#clipping);
    clip-path: url(#clipping);
    width: 689px;
    height: 385px;
    object-fit: cover;
    overflow: hidden; }

.video-screen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease, visibility 1s ease;
    z-index: 10;
    &.show {
        opacity: 1;
        visibility: visible;
        .video-container__close {
            opacity: 1;
            transition: opacity 1s ease;
            transition-delay: 1s; } } }

.video-container {
    &__close {
        position: absolute;
        top: 50px;
        right: 50px;
        width: 25px;
        height: 25px;
        z-index: 1;
        opacity: 0;
        svg {
            display: block;
            width: 100%;
            path {
                fill: #ffffff; } } }

    .video {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; } }


.video {
    width: 100%;
    height: 100%;
    display: block; }

.fancybox-slide--video {
    padding: 0; }

@include media-breakpoint-down(sm) {
    h1 {
        margin-top: 15px;
        font-size: 45px;
        font-weight: 700;
        text-transform: uppercase;
        font-family: Baskerville;
        color: #ffffff;
        margin-bottom: 15px; }

    .video-container {
        &__close {
            top: 20px;
            right: 20px; } } }

@include media-breakpoint-down(xs) {
    .promo {
        h4 {
            font-size: 18px;
            line-height: 24px; }
        .play-video {
            bottom: 0;
            right: 0; } }

    h1 {
        font-size: 40px; } }
