.section-text {
    position: relative;
    margin: 75px 0 120px;

    &--align-bg-left {
        .section-text__img {
            margin-left: -136px;
            &:before {
                right: 100px;
                background-image: url(../static/img/collection-swiper-bg.jpg);
                background-repeat: repeat; } } }
    &--align-bg-right {
        .section-text__img {
            margin-right: -136px;
            &:before {
                left: 100px;
                background-position: left center; } } }
    &--mar-bot {
        margin-bottom: 300px; }
    &--bg-green {
        .section-text__img {
            &:before {
                background-color: $green;
                background-image: none;
                opacity: 1; } } }
    &--green-bg {
        .section-text__img {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: 100px;
                left: 100px;
                width: 300%;
                height: 100%;
                background-color: $green;
                background-image: none;
                opacity: 1; }
            img {
                position: relative;
                z-index: 1; } } }
    &--green-bg.section-text--align-bg-left {
        .section-text__img {
            &:before {
                left: auto;
                right: 100px; } } }
    &__img {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: -75px;
            width: 300%;
            height: calc(100% + 150px);
            background-image: url(../static/img/product-page-bg.jpg);
            background-repeat: no-repeat;
            opacity: 0.5; }
        img {
            position: relative;
            z-index: 1;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__img--xl {
        width: 30vw;
        height: 53vh; }
    &__img--lg {
        // width: calc(100% + 125px)
        height: 53vh;
        width: 30vw; }
    &__img--lg-long {
        height: 70vh; }
    &__body {
        h2 {
            margin-bottom: 50px; }
        p {
            margin-bottom: 25px;
            &:last-child {
                margin-bottom: 0; } } }
    &--long-bg {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 225px;
            left: 100px;
            width: 100%;
            height: calc(100% - 325px);
            background-image: url(../static/img/product-page-bg.jpg);

            opacity: 0.5; }
        .section-text__img {
            &:before {
                display: none; } } } }

@media (max-width: 1600px) {
    .section-text {
        &--align-bg-left {
            &:before {
                left: -200px; } }
        &--align-bg-right {
            &:before {
                right: -200px; } }
        &__img--xl {
            width: 35vw; } } }


@media (max-width: 1300px) {
    .section-text {
        &--align-bg-left {
           .section-text__img {
                margin-left: -70px; } }
        &__img--xl {
            width: 40vw; } } }

@include media-breakpoint-down(lg) {
    .section-text {
        &--mar-bot {
            margin-bottom: 250px; }
        &--align-bg-left {
            &:before {
                left: -300px; } }
        &--align-bg-right {
            &:before {
                right: -350px; } }
        &__img--lg {
            width: 40vw; }
        &__img--xl {
            width: 45vw; }
        &--long-bg {
            &:before {
                content: '';
                left: 50px;
                height: calc(100% - 355px); } } } }

@include media-breakpoint-down(md) {
    .section-text {
        &:before {
            display: none; }
        &--mar-bot {
            margin-bottom: 200px; }
        &__img {
            margin-bottom: 75px;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: -50px;
                left: -155px;
                width: calc(100% + 100px);
                height: calc(100% + 100px);
                background-image: url(../static/img/product-page-bg.jpg);
                background-repeat: no-repeat;
                background-position: left center;
                opacity: 0.5; }
            img {
                position: relative;
                z-index: 1; } }
        &--align-bg-left {
            .section-text__img {
                width: 100%;
                height: 100%;
                margin-left: 0; } }
        &--align-bg-right {
            .section-text__img {
                width: 100%;
                height: 100%;
                margin-left: 0; }
            &:before {
                right: -350px; } }
        &__body {
            h2 {
                margin-bottom: 30px; }
            p {
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0; } } }
        &--green-bg {
            .section-text__img {
                &:before {
                    top: 50px; } } }
        &--long-bg {
            padding-top: 0;
            .section-text__img {
                width: 100%;
                height: 100%; }
            &:before {
                height: calc(100% - 250px); } } }


    .section-text--mar-bot.section-text--mar-bot-md {
        margin-bottom: 100px; } }


@include media-breakpoint-down(sm) {
    .section-text {
        margin-top: 50px;
        margin-bottom: 50px;
        &__body {
            h2 {
                margin-bottom: 25px; } }
        &--mar-bot {
            margin-bottom: 150px; } } }
