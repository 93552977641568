.form {
    &__text {
        font-size: 14px;
        line-height: 24px;
        font-family: Baskerville;
        color: rgba(5, 28, 38, 0.5);
        margin-bottom: 30px; }
    .btn-link {
        margin-top: 30px; } }

.input {
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.5);
    line-height: 60px;
    height: 60px;
    margin-bottom: 30px;
    font-family: Baskerville;
    font-size: 18px;
    font-weight: 700;
    color: rgba(5, 28, 38, 0.5);
    background-color: transparent;
    &::placeholder {
        color: rgba(5, 28, 38, 0.5); } }

@include media-breakpoint-down(xs) {
    .form__text {
        margin-bottom: 20px; } }
