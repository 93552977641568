.article {
    .container {
        h2 {
            margin-bottom: 40px; }

        img {
            width: 100%;
            display: block;
            margin: 60px auto; }

        p {
            margin: 0 0 40px;
            &:last-child {
                margin-bottom: 0; } }
        ul {
            font-size: 14px;
            line-height: 24px;
            color: rgba(5, 28, 38, 0.5);
            margin: 0px 0 40px 70px;
            li {
                position: relative;
                margin-bottom: 15px;
                padding-left: 30px;
                &:last-child {
                    margin-bottom: 0; }
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 7px;
                    height: 7px;
                    background-color: $gold;
                    border-radius: 50%; } } }
        ol {
            font-size: 14px;
            line-height: 24px;
            color: rgba(5, 28, 38, 0.5);
            margin: 0px 0 40px 70px;
            list-style-type: none;
            padding: 0;
            li {
                position: relative;
                margin-bottom: 15px;
                padding-left: 30px;
                &:last-child {
                    margin-bottom: 0; }
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 7px;
                    height: 7px;
                    background-color: $gold;
                    border-radius: 50%; } } }

        blockquote {
            font-size: 18px;
            line-height: 24px;
            font-style: italic;
            width: calc(100% - 200px);
            margin-left: 100px;
            margin-bottom: 40px; } }
    img {
        display: block;
        width: 100%;
        margin: 100px 0; } }

.article-footer {
    padding-top: 60px; }

@include media-breakpoint-down(lg) {
    .article {
        img {
            margin: 100px 0; } }
    .article-footer {
        margin-bottom: 50px; } }

@include media-breakpoint-down(md) {
    .article {
        .container {
            h2 {
                margin-bottom: 60px; }
            img {
                margin: 60px auto; }
            blockquote {
                font-size: 16px; } } }

    .article-footer {
        margin-bottom: 100px; } }

@include media-breakpoint-down(sm) {
    .article {
        .container {
            h2 {
                margin-bottom: 60px; }
            img {
                width: 100%; }
            blockquote {
                width: auto;
                margin: 40px 20px; }
            ul {
                margin-left: 20px; }
            ol {
                margin-left: 20px; } }
        img {
            margin: 80px 0; } } }

@include media-breakpoint-down(xs) {
    .article {
        .container {
            h2 {
                margin-bottom: 20px; }
            img {
                width: 100%;
                margin: 40px 0; }
            blockquote {
                width: auto;
                margin: 30px 20px; }
            ul {
                margin-bottom: 30px;
                margin-left: 0px; }
            ol {
                margin-bottom: 30px;
                margin-left: 0px; }
            p {
                margin-bottom: 30px; } }
        img {
            margin: 60px 0; } }
    .article-footer {
        margin-bottom: 50px;
        .btn-link {
            margin-bottom: 30px; } } }
