.post-card {
    margin-bottom: 50px;
    &__title {
        margin-bottom: 30px; }

    &__img {
        display: block;
        width: 100%;
        margin-bottom: 40px; }
    &__text {
        margin-bottom: 30px; }
    &--base {
        .post-card {
            &__title {
                font-weight: 400;
                text-transform: none;
                font-size: 20px;
                line-height: 30px; } } } }

@include media-breakpoint-down(md) {
    .post-card {
        &--base {
            .post-card__title {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 20px; } } } }
