.footer {
    padding-bottom: 100px;
    &__img {
        display: block;
        width: 100%;
        position: relative;
        z-index: 1; } }


.pages-menu {
    a {
        font-size: 14px;
        line-height: 40px;
        color: $black;
        display: inline-block;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            border-bottom: 1px solid $black;
            transform: scaleX(0);
            transition: transform 0.4s ease; }
        &:hover {
            &:after {
                transform: scaleX(1); } } } }



.copyright {
    padding: 80px 0;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../static/img/grey-pattern.jpg);
        background-position: center;
        opacity: 0.5; }
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 35%;
        height: 400px;
        background-color: $green; }
    .container {
        position: relative;
        z-index: 1; }
    &__text {
        color: rgba(5, 28, 38, 0.5);
        font-size: 14px; }
    &__link {
        color: rgba(5, 28, 38, 0.5);
        font-size: 14px;
        transition: color 0.4s ease;
        &:hover {
            color: $black; } } }

.contacts {
    font-size: 14px;
    line-height: 25px;
    color: $black;
    a {
        color: $black;
        text-decoration: underline;
        white-space: nowrap; } }

@include media-breakpoint-down(lg) {
    .copyright {
        &:after {
            width: 24%; } } }

@include media-breakpoint-down(md) {
    .footer {
        &__img {
            margin-top: 40px; } }


    .copyright {
        padding: 60px 0;
        .social-links {
            margin-bottom: 20px; }
        &__text {
            text-align: left;
            margin-bottom: 20px; }
        &:after {
            width: 40%; } } }

@include media-breakpoint-down(sm) {
    .copyright {
        &:after {
            width: 30%; } } }

@include media-breakpoint-down(xs) {
    .contacts {
        margin-top: 25px; }

    .copyright {
        padding: 30px 0;
        &:after {
            display: none; } }

    .footer {
        padding-bottom: 75px; } }
