.single-page {
    background-image: url(../static/img/bg.png);
    background-position: top center;
    background-repeat-y: repeat;
    padding: 100px 0 200px;
    position: relative; }

@include media-breakpoint-down(lg) {
    .single-page {
        padding: 100px 0; } }

@include media-breakpoint-down(md) {
    .single-page {
        padding: 50px 0; } }
