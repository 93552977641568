.consultation {
    position: fixed;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 6;
    overflow-y: auto;
    padding: 150px 0 50px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease, visibility 0.4s ease;
    &__close {
        position: fixed;
        top: 0;
        right: 0;
        width: 0;
        height: 84px;
        background-color: #fff;
        display: flex;
        align-items: center;
        z-index: 8;
        justify-content: flex-end;
        padding-right: 50px;
        visibility: hidden;
        transition: width 0.4s ease, visibilityy 0.4s ease;
        img {
            width: 25px;
            height: 25px;
            opacity: 0; }

        &.active {
            width: 300px;
            visibility: visible;
            img {
                transition: opacity 0.4s ease;
                transition-delay: 0.4s;
                opacity: 1; } } }
    .container {
        position: relative;
        z-index: 1; }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 180px;
        width: 40%;
        height: 0;
        background-image: url(../static/img/grey-pattern.jpg);
        opacity: 0.5;
        transition: height 0.4s ease; }
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 30%;
        height: 0;
        background-color: $green;
        transition: height 0.4s ease; }
    &.open {
        opacity: 1;
        visibility: visible;
        &:after {
            height: calc(100% - 70px); }
        &:before {
            height: 15%; } } }

@include media-breakpoint-down(md) {
    .consultation {
        transition: opacity 0s ease, visibility 0s ease;
        &__close {
            height: 78px;
            &.active {
                width: 200px; } } } }

@include media-breakpoint-down(sm) {
    .consultation {
        &__close {
            padding-top: 15px;
            padding-right: 15px;
            height: 93px;
            background-color: transparent; } } }

