.media-section {
    position: relative;
    &__bg-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        width: 1920px;
        height: 2150px;
        opacity: 0.5;
        background-image: url(../static/img/catalog-pattern.jpg); }
    &__bg-img--short {
        height: 1500px; }
    img {
        display: block;
        width: 100%; } }


@include media-breakpoint-down(lg) {
    .media-section {
        &__bg-img {
            height: 1800px; }
        &__bg-img--short {
            height: 1500px; } } }

@include media-breakpoint-down(md) {
    .media-section {
        &__bg-img {
            height: 2900px; }
        &__bg-img--short {
            height: 2000px; } } }

@include media-breakpoint-down(sm) {
    .media-section {
        &__bg-img {
            height: 2250px; }
        &__bg-img--short {
            height: 2000px; } } }

@include media-breakpoint-down(xs) {
    .media-section {
        &__bg-img {
            height: 5600px; }
        &__bg-img--short {
            height: 2000px; } } }
