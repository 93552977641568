@font-face {
	font-family: GothamPro;
	src: url('../static/fonts/GothamPro.eot');
	src: local('../static/fonts/GothamPro'), url('../static/fonts/GothamPro.eot?#iefix') format('embedded-opentype'), url('../static/fonts/GothamPro.woff') format('woff'), url('../static/fonts/GothamPro.ttf') format('truetype');
	font-weight: 400;
	font-style: normal; }

@font-face {
	font-family: GothamPro;
	src: url('../static/fonts/GothamPro-Medium.eot');
	src: local('../static/fonts/GothamPro-Medium'), url('../static/fonts/GothamPro-Medium.eot?#iefix') format('embedded-opentype'), url('../static/fonts/GothamPro-Medium.woff') format('woff'), url('../static/fonts/GothamPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal; }

@font-face {
	font-family: Baskerville;
	src: url('../static/fonts/baskerville_win95bt.eot');
	src: local('../static/fonts/baskerville_win95bt'), local('../static/fonts/baskerville_win95bt'), url('../static/fonts/baskerville_win95bt.eot?#iefix') format('embedded-opentype'), url('../static/fonts/baskerville_win95bt.woff') format('woff'), url('../static/fonts/baskerville_win95bt.ttf') format('truetype');
	font-weight: 400;
	font-style: normal; }

@font-face {
	font-family: Baskerville;
	src: url('../static/fonts/baskerville_italic_win95bt.eot');
	src: local('../static/fonts/baskerville_italic_win95bt'), local('../static/fonts/baskerville_italic_win95bt'), url('../static/fonts/baskerville_italic_win95bt.eot?#iefix') format('embedded-opentype'), url('../static/fonts/baskerville_italic_win95bt.woff') format('woff'), url('../static/fonts/baskerville_italic_win95bt.ttf') format('truetype');
	font-weight: 400;
	font-style: italic; }


@font-face {
	font-family: Baskerville;
	src: url('../static/fonts/baskerville_bold_win95bt.eot');
	src: local('../static/fonts/baskerville_bold_win95bt'), local('../static/fonts/baskerville_bold_win95bt'), url('../static/fonts/baskerville_bold_win95bt.eot?#iefix') format('embedded-opentype'), url('../static/fonts/baskerville_bold_win95bt.woff') format('woff'), url('../static/fonts/baskerville_bold_win95bt.ttf') format('truetype');
	font-weight: 700;
	font-style: normal; }
