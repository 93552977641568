.product {
    position: relative;
    margin: 75px 0 120px {

        opacity: 0.5; }
    &__header {
        margin-bottom: 50px; }
    &__body {
        margin-bottom: 50px; }
    &__footer {
        .row {
            &:first-child {
                margin-bottom: 40px; } } }
    &__gallery {
        position: relative;
        margin-left: -136px;
        width: 770px;
        height: 770px;

        .slider {
            position: relative;
            z-index: 1; } } }

.product-swiper-container {
    &:before {
        content: '';
        position: absolute;
        top: -75px;
        right: 150px;
        width: 300%;
        height: calc(100% + 150px);
        background-image: url(../static/img/catalog-pattern.jpg);
        background-repeat: no-repeat;
        background-position: right center;
        opacity: 0.5; } }

.product-swiper {
    width: calc(100% + 125px);
    height: 60vh;
    margin-left: -136px;
    a {
        display: block;
        img {
            display: block;
            width: 100%;
            object-fit: cover; } }
    .swiper-pagination {
        display: flex;
        padding-left: 50px;
        bottom: 50px; }
    .swiper-pagination-bullet {
        width: 15px;
        border-bottom: 2px solid #ffffff;
        background-color: transparent;
        margin: 0 15px 0 0 !important;
        border-radius: 0;
        transition: 0.4s ease; } }

.fancybox-overlay {
    background: rgba(255,255,255,0.75); }
.fancybox-skin {
    padding: 0 !important; }

@media (max-width: 1450px) {
    .product-swiper {
        margin-left: -50px; }

    .product-swiper-container {
        &:before {
            right: 50px; } } }

@include media-breakpoint-down(lg) {
    .product-swiper-container {
        &:before {
            right: 100px; } }

    .product {
        margin-bottom: 100px;
        &:before {
            right: 27%; }
        &__header {
            margin-bottom: 40px; }
        &__body {
            margin-bottom: 40px; } }
    .product-swiper {
        width: calc(100% + 50px); } }

@include media-breakpoint-down(md) {
    .product {
        margin-bottom: 0px;
        &:before {
            display: none; } }

    .product-swiper {
        margin-left: 0;
        width: 100%;
        height: 100%;
        margin-bottom: 100px;
        img {
            display: block;
            width: 100%; } }

    .product-swiper-container {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: -50px;
            left: -155px;
            width: calc(100% + 100px);
            height: calc(100% + 100px);
            background-image: url(../static/img/product-page-bg.jpg);
            background-repeat: no-repeat;
            background-position: left center;
            opacity: 0.5; } } }

@include media-breakpoint-down(sm) {
    .product {
        margin-top: 50px;
        margin-bottom: 0;
        &__header {
            margin-bottom: 30px; }
        &__body {
            margin-bottom: 30px; } }

    .product-swiper {
        margin-bottom: 75px; } }

@include media-breakpoint-down(xs) {
    .product-swiper {
        .swiper-pagination {
            bottom: 25px;
            padding-left: 25px; } } }
