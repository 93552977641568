body {
	overflow-x: hidden;
	color: $black;
	margin: 0;
	font-family: GothamPro;
	font-weight: 400;
	line-height: 1;
	cursor: url('../static/img/key-cursor.svg'), auto;
	width: 100%; }
a {
	cursor: url('../static/img/key-cursor.svg'), auto; }

body, html {
	&.disable-scroll {
		overflow: hidden;
		position: fixed; } }

.wrapper {
	overflow: hidden; }

.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	transition: opacity 1s ease, visibility 1s ease;
	&__right {
		background-color: #fff;
		width: 50%;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		transition: height 1s ease-in-out; }
	&__left {
		width: 50%;
		background-color: #fff;
		position: absolute;
		bottom: 0;
		right: 0;
		height: 100%;
		transition: height 1s ease-in-out; }
	&.hide {
		opacity: 0;
		visibility: hidden;
		.preloader {
			&__right {
				height: 0; }
			&__left {
				height: 0; } } } }


.mobile-display {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	z-index: 10000;
	&__logo {
		position: absolute;
		top: 25px;
		left: 50%;
		transform: translateX(-50%); }
	&__icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		img {
			margin-bottom: 15px; } } }
// cursor start

// .cursor
// 	position: fixed
// 	left: 0
// 	top: 0
// 	pointer-events: none

// .cursor--small
// 	width: 5px
// 	height: 5px
// 	left: -2.5px
// 	top: -2.5px
// 	border-radius: 50%
// 	z-index: 11000
// 	background: var(--color-text)

// .cursor--canvas
// 	width: 100vw
// 	height: 100vh
//-index: 12000

// cursor end

// nav start
// .nav
// 	display: flex
// 	position: absolute
// 	left: 50%
// 	top: 50%
// 	//If you use compass, instead of the line below you could use + transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
// 	transform: translate(-50%, -50%)

// .link
// 	display: flex
// 	width: 75px
// 	height: 75px
// 	margin: 0 5px
// 	justify-content: center
// 	align-items: center
// 	&:hover
// 		.settings-icon__line
// 			stroke: red
// 			//If you use compass, instead of the line below you could use + transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
// 			transition: all 0.2s ease 0.05s

// 		.settings-icon__rect
// 			stroke: red
// 			//If you use compass, instead of the line below you could use + transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
// 			transition: all 0.2s ease 0.05s

// 		.settings-icon__group--1 .settings-icon__rect
// 			//If you use compass, instead of the line below you could use + transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
// 			transform: translateY(20px)

// 		.settings-icon__group--2 .settings-icon__rect
// 			//If you use compass, instead of the line below you could use + transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
// 			transform: translateY(-20px)

// 		.settings-icon__group--3 .settings-icon__rect
// 			//If you use compass, instead of the line below you could use + transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
// 			transform: translateY(25px)


// .settings-icon
// 	display: block
// 	width: 40px
// 	height: 40px

// .settings-icon__line
// 	stroke: #fff
// 	stroke-width: 5px
// 	//If you use compass, instead of the line below you could use + transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
// 	transition: all 0.2s ease 0.05s

// .settings-icon__rect
// 	stroke: #fff
// 	fill: red
// 	stroke-width: 5px
// 	//If you use compass, instead of the line below you could use + transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
// 	transition: all 0.2s ease 0.05s
// nav end
