@import "~bootstrap/scss/bootstrap-reboot.scss";
@import "~bootstrap/scss/bootstrap-grid.scss";
@import "~bootstrap/scss/utilities/_flex";
@import "~swiper/dist/css/swiper.min.css";
@import "~malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";

@import "fonts.sass";
@import "reset.sass";
@import "vars.sass";
@import "base.sass";
@import "common.sass";
@import "header.sass";
@import "menu.sass";
@import "promo.sass";
@import "favourites.sass";
@import "consultation.sass";
@import "single-page.sass";
@import "product-card.sass";
@import "collection-card.sass";
@import "product.sass";
@import "footer.sass";
@import "catalog.sass";
@import "media-section.sass";
@import "form.sass";
@import "blog.sass";
@import "post-card.sass";
@import "article.sass";
@import "service-section.sass";
@import "categories-section.sass";
@import "section-text.sass";
@import "section-items.sass";
