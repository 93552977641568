.favourites {
    position: fixed;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 6;
    overflow-y: auto;
    padding: 150px 0 50px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease, visibility 0.4s ease;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 180px;
        width: 40%;
        height: 0;
        background-image: url(../static/img/grey-pattern.jpg);
        opacity: 0.5;
        transition: height 0.4s ease; }
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 30%;
        height: 0;
        background-color: $green;
        transition: height 0.4s ease; }
    &__body {
        width: 100%;
        position: relative;
        z-index: 1;
        overflow: hidden; }
    &__amount {
        font-size: 25px;
        color: $black;
        font-weight: 700;
        font-family: Baskerville; }
    &__header {
        margin-bottom: 60px; }
    &__header-icon {
        svg {
            path {
                fill: transparent;
                stroke: $black;
                transition: 0.4s ease; } } }
    &.open {
        opacity: 1;
        visibility: visible;
        &:after {
            height: calc(100% - 70px); }
        &:before {
            height: 15%; } } }

.favourites-products {
    &__header {
        margin-bottom: 50px; }
    &__title {
        margin-bottom: 50px; }
    &__list {
        max-height: 360px;
        overflow-y: scroll;
        overflow-x: hidden;
        .simplebar-track.simplebar-horizontal {
            display: none !important; }
        .simplebar-track {
            right: -25px; }
        .simplebar-scrollbar {
            width: 4px; } }
    &__list-item {
        margin-bottom: 30px;
        display: block;
        &:last-child {
            margin-bottom: 0; } } }

.favourites-products {
    &__item {
        display: block; }
    &__item-img {
        display: block;
        width: 100%; }
    &__item-title {
        padding: 0 20px; } }

.favourites-products__item-img {
    img {
        display: block;
        width: 100%; } }

@include media-breakpoint-down(md) {
    .favourites-products {
        margin-bottom: 100px; }
    .favourites-products__list {
        max-height: 435px; }

    .favourites {
        &:after {
            left: 100px; }
        &:before {
            display: none; }
        &.open {
            &:after {
                height: calc(100% - 100px); } } } }

@include media-breakpoint-down(sm) {
    .favourites {
        &:after {
            left: 0;
            width: 50%; }
        &.open {
            &:after {
                height: 100%; } } }

    .favourites-products__list {
        max-height: 100%; }

    .favourites-products {
        margin-bottom: 50px; } }

@include media-breakpoint-down(xs) {
    .favourites {
        padding: 115px 0 50px; }


    .favourites-products {
        margin-bottom: 50px;
        &__item-img {
            margin-bottom: 15px; }
        &__item-title {
            padding: 0px 10px 0 0;
            text-align: left; }
        &__title {
            margin-bottom: 30px; }
        &__header {
            margin-bottom: 40px; } }

    .favourites-products__list {
        max-height: 100%; } }


