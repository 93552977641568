.catalog-list {
    margin-bottom: 150px;
    .product-card {
        margin-bottom: 30px; } }

.popular-products {
    padding-top: 0; }

.pagination {
    display: flex;
    width: 100%;
    justify-content: center;
    li {
        margin: 0 12.5px; }
    a {
        font-size: 16px;
        color: $black;
        transition: 0.4s ease;
        display: block;
        padding: 10px;
        font-weight: 500;
        &:hover {
            color: $gold; } }
    .next, .prev {
        svg {
            polygon {
                fill: $black;
                transition: 0.4s ease; } }
        &:hover {
            svg {
                polygon {
                    fill: $gold; } } } } }

.filter {
    position: absolute;
    top: 57px;
    left: 0;
    padding: 50px;
    z-index: 5;
    &__block {
        margin-bottom: 50px; }
    &__block-title {
        font-size: 16px;
        color: rgba(5, 28, 38, 0.5);
        margin-bottom: 25px; } }

.checkbox {
    position: relative;
    cursor: url('../static/img/key-cursor.svg'), auto;
    &__input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        &:checked ~ .checkbox__text {
            padding-left: 20px;
            &:before {
                opacity: 1; } } }
    &__text {
        font-size: 14px;
        line-height: 14px;
        color: $black;
        position: relative;
        transition: padding-left 0.4s ease, color 0.4s ease;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 10px;
            border-bottom: 1px solid $black;
            opacity: 0;
            transition: opacity 0.4s ease; } }
    &:hover {
        .checkbox {
            &__text {
                color: $gold; } } } }

.filter-btn {
    margin-bottom: 50px; }

@media (max-width: 1550px) {
    .filter {
        position: relative;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 50px; } }

@include media-breakpoint-down(md) {
    .catalog-list {
        margin-bottom: 100px; }

    .filter {
        flex-wrap: wrap;
        padding-bottom: 50px;
        &__block {
            width: 25%;
            margin-bottom: 30px; } }

    .popular-products {
        .col-lg-4 {
            &:last-child {
                display: none; } } } }

@include media-breakpoint-down(sm) {
    .catalog-list {
        margin-bottom: 50px; }

    .pagination {
        li {
            margin: 0 5px; }
        a {
            font-size: 16px;
            color: $black;
            transition: 0.4s ease;
            display: block;
            padding: 10px;
            font-weight: 500;
            &:hover {
                color: $gold; } }
        .next, .prev {
            svg {
                polygon {
                    fill: $black;
                    transition: 0.4s ease; } }
            &:hover {
                svg {
                    polygon {
                        fill: $gold; } } } } }

    .filter-wrap {
        display: none; }

    .filter {
        justify-content: flex-start;
        padding: 0 15px 50px;
        &__block {
            width: 33.3333%; } }

    .popular-products {
        .col-lg-4 {
            &:nth-child(1), &:nth-child(2) {
                .product-card {
                    margin-bottom: 30px; } } } } }

@include media-breakpoint-down(xs) {
    .filter {
        &__block {
            width: 50%; } }

    .popular-products {
        .col-lg-4 {
            &:last-child {
                display: block; } } } }
