.blog-media {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../static/img/product-page-bg.jpg);
        opacity: 0.5; }
    &__img-container {
        width: 750px;
        position: relative;
        margin-left: -180px;
        img {
            display: block;
            width: 100%; } }
    .post-card {
        margin-bottom: 0;
        margin-left: 40px; } }

.blog-section {
    .col-md-4 {
        .row {
            &:last-child {
                .post-card {
                    margin-bottom: 0; } } } } }

@media (max-width: 1600px) {
    .blog-media {
        &__img-container {
            width: 700px;
            margin-left: -120px; } } }


@media (max-width: 1380px) {
    .blog-media {
        &__img-container {
            width: 650px;
            margin-left: -70px; } } }

@include media-breakpoint-down(lg) {
    .blog-media {
        &__img-container {
            width: calc(100% + 200px);
            margin-left: -100px; } } }

@include media-breakpoint-down(md) {
    .blog-media {
        &__img-container {
            width: 100%;
            margin-left: 0;
            margin-bottom: 30px; }
        .post-card {
            margin-left: 0; } } }
