.header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__controlls {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 50px;
        position: relative;
        z-index: 7;
        transition: opacity 0.4s ease, visibillity 0.4s ease;
        &.hidden {
            opacity: 0;
            visibility: hidden; } }
    &__socials {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 35px 80px;
        background-color: $green;
        margin-left: 60px;
        li {
            margin-right: 25px;
            &:last-child {
                margin-right: 0; } }
        a {
            font-size: 14px;
            color: rgba(5, 28, 38, 0.5);
            transition: color 0.4s ease;
            display: block;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                bottom: -4px;
                left: 0;
                transform: scaleX(0);
                width: 100%;
                border-bottom: 1px solid $black;
                transition: transform 0.4s ease; }
            &:hover {
                color: $black;
                &:after {
                    transform: scaleX(1); } } } }
    &.header--absolute {
        position: absolute;
        top: 25px;
        left: 0;
        width: 100%;
        .logo {
            svg {
                path {
                    fill: #ffffff; } } }
        .header__socials {
            display: none !important; }
        .lang-switch {
            &__current-lang {
                color: #ffffff;
                &:after {
                    background-image: url(../static/img/arrow-down-white.svg); } }
            ul {
                li {
                    a {
                        color: #ffffff; } } } }
        .favorites {
            &__icon {
                svg {
                    path {
                        stroke: #ffffff; } } } }
        .btn-menu {
            span {
                border-bottom: 2px solid #ffffff; } } }
    &.header--black {
        .logo {
            svg {
                path {
                    fill: $black; } } }
        .lang-switch {
            &__current-lang {
                color: $black;
                &:after {
                    background-image: url(../static/img/arrow-down.svg); } }
            ul {
                li {
                    a {
                        color: $black; } } } }
        .favorites {
            &__icon {
                svg {
                    path {
                        stroke: $black; } } } }
        .btn-menu {
            span {
                border-bottom: 2px solid $black; } } } }


.logo {
    margin: 0px 0 0 50px;
    display: block;
    position: relative;
    z-index: 7;
    svg {
        max-width: 100%;
        path {
            fill: $black; } } }

.lang-switch {
    margin-right: 45px;
    position: relative;
    &__current-lang {
        position: relative;
        text-transform: uppercase;
        font-size: 16px;
        padding: 10px 0;
        &:after {
            content: '';
            position: absolute;
            top: 55%;
            transform: translateY(-50%);
            right: -20px;
            width: 10px;
            height: 5px;
            background-image: url(../static/img/arrow-down.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center; } }
    ul {
        position: absolute;
        top: 35px;
        left: 0;
        li {
            margin-bottom: 8px;
            opacity: 0;
            transform: translateY(10px);
            visibility: hidden;
            transition: transform 0.4s ease, opacity 0.4s ease, visibility 0.4s ease;

            &:last-child {
                margin-bottom: 0; }
            &.current-lang {
                display: none; } }
        a {
            text-transform: uppercase;
            font-size: 16px;
            color: $black;
            transition: color 0.4s ease;
            &:hover {
                color: $gold; } } }
    &:hover {
        ul {
            li {
                transform: translateY(0);
                opacity: 1;
                visibility: visible; } } } }

.favorites {
    position: relative;
    margin-right: 30px;
    display: block;

    &__icon {
        width: 19px;
        height: 16px;
        svg {
            path {
                fill: transparent;
                stroke: $black; } } }
    &__amount {
        position: absolute;
        top: -4px;
        right: -6px;
        width: 16px;
        height: 16px;
        background-color: $gold;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        font-weight: 500;
        color: $black; } }

.btn-menu {
    width: 30px;
    position: relative;
    span {
        width: 100%;
        display: block;
        border-bottom: 2px solid $black;
        margin: 5px 0;
        transition: 0.4s ease; }
    &.active {
        span {
            width: 80%;
            &:first-child {
                transform: rotate(45deg) translate(1px, 4px); }
            &:last-child {
                transform: rotate(-45deg) translate(1px, -3px); } } } }


@include media-breakpoint-down(lg) {
    .header {
        &__socials {
            padding: 50px 90px;
            margin-left: 80px; } } }

@include media-breakpoint-down(md) {
    .header {
        &__socials {
            padding: 30px 30px;
            margin-left: 0; } } }

@include media-breakpoint-down(sm) {
    .header {
        padding-top: 15px;
        &__controlls {
            margin-right: 15px; }
        &.header--absolute {
            top: 0; } }
    .logo {
        margin-left: 15px;
        width: 50px; } }
