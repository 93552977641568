.categories-section {
    position: relative;
    overflow: hidden;
    &__row {
        &:last-child {
            margin-top: -320px; } }
    &:before {
        content: '';
        position: absolute;
        top: 40%;
        right: 0;
        width: 80%;
        height: 40%;
        background-color: $green;
        z-index: 1; }
    .container {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: -150px;
            width: 70%;
            height: 80%;
            right: -80px;
            background-image: url(../static/img/collection-swiper-bg.jpg);
            opacity: 0.5; } } }

.brands-swiper {
    .swiper-slide {
        padding: 100px 0; }
    h2 {
        margin-bottom: 50px; }
    p {
        margin-bottom: 50px; }
    .swiper-controlls {
        position: absolute;
        top: 105px;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center; }
    .swiper-button-next {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: none;
        background-color: transparent;
        position: relative;
        left: auto;
        right: auto;
        top: 0;
        margin: 0;
        z-index: 2;
        transition: background-color 0.4s ease;
        svg {
            polygon {
                transition: 0.4s ease;
                fill: $gold; } }
        &:hover {
            background-color: $gold;
            svg {
                polygon {
                    fill: #ffffff; } } }
        &:focus {
            outline: none; } }
    .swiper-button-prev {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: none;
        background-color: transparent;
        position: relative;
        left: auto;
        right: auto;
        top: 0;
        margin: 0;
        z-index: 2;
        transition: background-color 0.4s ease;
        svg {
            polygon {
                transition: 0.4s ease;
                fill: $gold; } }
        &:hover {
            background-color: $gold;
            svg {
                polygon {
                    fill: #ffffff; } } }
        &:focus {
            outline: none; } } }

.category-swiper {
    .swiper-pagination {
        display: flex;
        padding-left: 50px;
        bottom: 50px; }
    .swiper-pagination-bullet {
        width: 15px;
        border-bottom: 2px solid #ffffff;
        background-color: transparent;
        margin: 0 15px 0 0 !important;
        border-radius: 0;
        transition: 0.4s ease; }
    .category-item {
        display: block;
        width: 100%;
        height: 100%;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover; } } }

.category-swiper-wrapper {
    position: relative;
    overflow: hidden; }

.category-swiper__title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: rgba(255,255,255,0.2);
    font-family: Baskerville;
    font-weight: 700;
    z-index: 2; }


.category-swiper-wrapper-1 {
    max-width: 600px;
    width: 35vw;
    left: 100px;
    .category-swiper__title {
        top: -30px;
        font-size: 170px; }
    .swiper-slide {
        height: 80vh;
        max-height: 900px;
        min-height: 700px; } }

.category-swiper-wrapper-2 {
    max-width: 420px;
    left: -240px;
    width: 420px;
    margin-top: 200px;
    .category-swiper__title {
        top: -40px;
        font-size: 118px; }
    .swiper-slide {
        height: 760px;
        max-height: 760px; } }

.category-swiper-wrapper-3 {
    width: 100%;
    .category-swiper__title {
        top: -30px;
        font-size: 95px; }
    .swiper-slide {
        height: 500px;
        max-height: 500px; } }

.category-swiper-wrapper-4 {
    width: calc(100% + 60px);
    height: 550px;
    margin-top: 100px;
    left: -30px;
    max-height: 550px;
    .category-swiper__title {
        top: -35px;
        font-size: 135px; }
    .swiper-slide {
        height: 550px;
        max-height: 550px; } }

.category-swiper-wrapper-5 {
    width: calc(100% + 70px);
    top: 420px;
    .category-swiper__title {
        top: -25px;
        font-size: 75px; }
    .swiper-slide {
        height: 600px; } }

@media (max-width: 1650px) {
    .category-swiper-wrapper-1 {
        left: 15px;
        .category-swiper__title {
            font-size: 140px; } }

    .category-swiper-wrapper-2 {
        left: -180px; }

    .category-swiper-wrapper-4 {
        width: 100%;
        left: 0;
        .category-swiper__title {
            font-size: 115px; } }

    .category-swiper-wrapper-5 {
        left: -80px; } }

@include media-breakpoint-down(lg) {
    .categories-section {
        &__row {
            &:last-child {
                margin-top: -100px; } }
        &:before {
            width: 80%; }
        .container {
            position: relative;
            &:before {
                width: 50%; } } }

    .category-swiper-wrapper-1 {
        height: 600px;
        width: 100%;
        left: 0;
        .category-swiper__title {
            font-size: 125px; } }

    .category-swiper-wrapper-2 {
        left: 0;
        width: 100%;
        margin-top: 0;
        max-width: 100%;
        .category-swiper__title {
            font-size: 126px; } }

    .category-swiper-wrapper-3 {
        margin-top: 200px;
        width: 100%;
        .category-swiper__title {
            font-size: 115px; } }

    .category-swiper-wrapper-4 {
        width: 100%;
        .category-swiper__title {
            font-size: 150px;
            top: -50px; } }

    .category-swiper-wrapper-5 {
        top: 0;
        left: 0;
        width: 100%;
        margin-top: -500px;
        .category-swiper__title {
            font-size: 80px; } } }

@include media-breakpoint-down(md) {
    .brands-swiper {
        .swiper-slide {
            padding-top: 0; }
        .swiper-controlls {
            top: 5px; } }

    .categories-section {
        &__row {
            &:last-child {
                margin-top: 100px; } }
        &:before {
            width: 70%;
            height: 70%;
            top: 20%; }
        .container {
            position: relative;
            &:before {
                width: 50%;
                height: 20%; } } }

    .category-swiper-wrapper-1 {
        height: auto;
        .swiper-slide {
            height: auto;
            max-height: 100%;
            min-height: auto; } }

    .category-swiper-wrapper-2 {
        height: auto;
        .swiper-slide {
            height: auto;
            max-height: 100%; } }

    .category-swiper-wrapper-3 {
        margin-top: 100px;
        .swiper-slide {
            height: auto;
            max-height: 100%; } }

    .category-swiper-wrapper-4 {
        left: -16.66667%;
        height: auto;
        max-height: 100%;
        .swiper-slide {
            height: auto;
            max-height: 100%; } }

    .category-swiper-wrapper-5 {
        margin-top: 100px;
        .swiper-slide {
            height: auto;
            max-height: 100%; } } }

@include media-breakpoint-down(sm) {
    .categories-section {
        &:before {
            display: none; }
        &__row:last-child {
            margin-top: 50px; } }

    .category-swiper-wrapper-1 {
        .category-swiper__title {
            font-size: 150px; } }

    .category-swiper-wrapper-2 {
        .category-swiper__title {
            font-size: 150px; } }

    .category-swiper-wrapper-3 {
        margin-top: 50px;
        .category-swiper__title {
            font-size: 130px; } }

    .category-swiper-wrapper-4 {
        margin-top: 50px;
        left: 0;
        .category-swiper__title {
            text-align: center; } }

    .category-swiper-wrapper-5 {
        margin-top: 50px;
        .category-swiper__title {
            font-size: 92px; } } }

@include media-breakpoint-down(xs) {
    .brands-swiper {
        h2 {
            margin-bottom: 30px; }
        p {
            margin-bottom: 30px; }
        .swiper-controlls {
            top: -5px; } }

    .category-swiper-wrapper-1 {
        .category-swiper__title {
            position: relative;
            color: $black;
            font-size: 30px;
            top: 0;
            margin-bottom: 20px; } }

    .category-swiper-wrapper-2 {
        .category-swiper__title {
            position: relative;
            color: $black;
            font-size: 30px;
            top: 0;
            margin-bottom: 20px; } }

    .category-swiper-wrapper-3 {
        .category-swiper__title {
            position: relative;
            color: $black;
            font-size: 30px;
            top: 0;
            margin-bottom: 20px; } }

    .category-swiper-wrapper-4 {
        left: 0;
        .category-swiper__title {
            position: relative;
            color: $black;
            font-size: 30px;
            top: 0;
            margin-bottom: 20px;
            text-align: left; } }

    .category-swiper-wrapper-5 {
        .category-swiper__title {
            position: relative;
            color: $black;
            font-size: 30px;
            top: 0;
            margin-bottom: 20px;
            text-align: left; } } }
