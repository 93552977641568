.product-additions {
    display: flex;
    align-items: flex-start;
    &__item {
        width: calc(100% / 3); } }

.product-card {
    display: block;
    &--xl {
        .product-card {
            &__footer {
                padding: 50px 50px 0; } } }
    &__img {
        display: block;
        width: 100%;
        img {
            display: block;
            width: 100%; } }
    &__footer {
        padding: 20px 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center; }
    &__title {
        font-size: 18px;
        color: $black;
        letter-spacing: -0.9px;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 17px;
            border-bottom: 1px solid $black;
            transition: width 0.4s ease; } }
    &:hover {
        .product-card {
            &__title {
                &:after {
                    width: calc(100% + 15px); } } } } }



@include media-breakpoint-down(md) {
    .product-additions-section {
        padding-bottom: 50px; } }

@include media-breakpoint-down(sm) {
    .product-additions {
        display: block;
        &__item {
            width: 100%;
            margin-bottom: 40px;
            &:last-child {
                margin-bottom: 0; } } }

    .product-card {
        &--xl {
            .product-card {
                &__footer {
                    padding: 20px 20px 0; } } } } }
