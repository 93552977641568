.section-items {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 250px;
        right: 200px;
        width: 100%;
        height: 60%;
        background-image: url(../static/img/catalog-pattern.jpg);
        opacity: 0.5; } }

.section-item {
    &__img {
        position: relative;
        width: calc(100% + 30px);
        left: -15px;
        img {
            position: relative;
            z-index: 1;
            display: block;
            object-fit: cover;
            width: 100%; } }
    &__img--move-left {
        left: -100px;
        &:before {
            content: '';
            position: absolute;
            bottom: 100px;
            right: -100px;
            width: 400%;
            height: calc(100% - 200px);
            background-color: $green; } }
    h3 {
        text-transform: none;
        margin-bottom: 35px; }
    &:nth-child(1) {}
    &:nth-child(2) {
        .section-item__content {
            padding-top: 110px; } }
    &:nth-child(3) {
        margin-top: -225px;
        .section-item__content {
            padding-bottom: 50px; } } }

@include media-breakpoint-down(lg) {
    .section-items {
        &:before {
            top: 150px;
            height: 65%; } }

    .section-item {
        &:nth-child(3) {
            margin-top: -125px; } } }

@include media-breakpoint-down(md) {
    .section-item {
        &__img {
            margin-bottom: 40px;
            left: 0;
            width: 100%; }
        h3 {
            margin-bottom: 20px; }
        &__content {
            margin-bottom: 100px; }
        &:nth-child(2) {
            .section-item__content {
                padding-top: 0; } }
        &:nth-child(3) {
            margin-top: 0;
            .section-item__content {
                margin-bottom: 0;
                padding-bottom: 0; } }
        &__img--move-left {
            left: 0; } } }

@include media-breakpoint-down(sm) {
    .section-items {
        padding: 50px 0; }

    .section-item {
        &__content {
            margin-bottom: 100px; } } }
