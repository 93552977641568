.collection-section {
    position: relative;

    .section-header {
        margin-bottom: 60px; }
    &.collection-section {
        margin-bottom: 150px; } }

.collection-swiper {
    width: 100%;
    position: relative;
    overflow: inherit;
    &:after {
        content: '';
        position: absolute;
        bottom: -140px;
        right: -37%;
        height: calc(100% + 280px);
        width: 780px;
        background-image: url(../static/img/collection-swiper-bg.jpg);
        background-repeat: no-repeat;
        opacity: 0.5; }
    &:before {
        content: '';
        position: absolute;
        bottom: -140px;
        left: -180px;
        background-color: $green;
        width: 630px;
        height: 605px; }
    .swiper-controlls {
        display: flex;
        justify-content: center;
        align-items: center; }
    .swiper-button-next {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: none;
        background-color: transparent;
        position: relative;
        left: auto;
        right: auto;
        top: 0;
        margin: 0;
        z-index: 2;
        transition: background-color 0.4s ease;
        svg {
            polygon {
                transition: 0.4s ease;
                fill: $gold; } }
        &:hover {
            background-color: $gold;
            svg {
                polygon {
                    fill: #ffffff; } } }
        &:focus {
            outline: none; } }
    .swiper-button-prev {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: none;
        background-color: transparent;
        position: relative;
        left: auto;
        right: auto;
        top: 0;
        margin: 0;
        z-index: 2;
        transition: background-color 0.4s ease;
        svg {
            polygon {
                transition: 0.4s ease;
                fill: $gold; } }
        &:hover {
            background-color: $gold;
            svg {
                polygon {
                    fill: #ffffff; } } }
        &:focus {
            outline: none; } } }

.collection-card {
    display: block !important;
    width: 100%;
    position: relative;
    &__title {
        position: absolute;
        bottom: 30px;
        left: 30px;
        color: $black;
        font-size: 25px;
        text-transform: uppercase;
        font-family: Baskerville;
        font-weight: 700;
        &:after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 0;
            border-bottom: 2px solid $black;
            transition: width 0.4s ease; } }
    img {
        display: block;
        width: 100%;
        object-fit: cover; }
    &:hover {
        .collection-card {
            &__title {
                &:after {
                    width: 100%; } } } } }

@media (max-width: 1350px) {
    .collection-swiper {
        width: 80%;
        margin: 0;
        &:before {
            width: 530px;
            height: 505px;
            bottom: -60px; }
        &:after {
            height: calc(100% + 100px);
            bottom: -30px;
            right: -60%; } } }

@include media-breakpoint-down(lg) {
    .collection-swiper {
        width: 100%; }

    .collection-section {
        &.collection-section {
            margin-bottom: 100px; } } }

@include media-breakpoint-down(md) {
    .collection-swiper {
        &:before {
            width: 330px;
            height: 305px;
            bottom: -30px; }
        &:after {
            height: calc(100% + 100px);
            bottom: -30px;
            right: -90%; } }

    .collection-section {
        &.collection-section {
            margin-bottom: 50px; } } }

@include media-breakpoint-down(sm) {
    .collection-section {
        padding-top: 50px;
        .section-header {
            margin-bottom: 100px; } }

    .collection-swiper {
        &:before {
            width: 230px;
            height: 205px;
            bottom: 0px; }
        &:after {
            height: calc(100% + 50px);
            bottom: -5px;
            right: -90%; } }

    .collection-section {
        &.collection-section {
            margin-bottom: 0px; } } }

@include media-breakpoint-down(xs) {
    .collection-section {
        padding-bottom: 25px; }

    .collection-swiper {
        &:after {
            width: 150px;
            right: -30px; } } }
