ul {
	margin: 0;
	padding: 0;
	list-style-type: none; }

p {
	margin: 0;
	padding: 0; }

h1,h2,h3,h4,h5,h6 {
	margin: 0;
	padding: 0;
	font-weight: normal; }

a {
	text-decoration: none;
	&:hover {
		outline: none;
		text-decoration: none; }
	&:focus {
		outline: none;
		text-decoration: none; } }

input, button {
	&:hover,&:focus {
		outline: none; } }
