.section {
    padding: 150px 0;
    &.section--no-pad-top {
        padding-top: 0; }
    &.section--no-pad-bot {
        padding-bottom: 0; } }

.section-header {
    margin-bottom: 50px;
    p {
        max-width: 600px;
        margin-top: 30px; }
    a {
        margin-top: 30px; } }

h2, .h2 {
    font-size: 50px;
    line-height: 60px;
    color: $black;
    letter-spacing: -2.5px;
    font-family: Baskerville; }

h3 {
    font-size: 25px;
    line-height: 35px;
    font-weight: 700;
    color: $black;
    font-family: Baskerville;
    text-transform: uppercase; }

h4 {
    font-size: 18px;
    letter-spacing: -0.9px;
    color: $black;
    line-height: 1; }

p {
    font-size: 14px;
    line-height: 21px;
    color: rgba(5, 28, 38, 0.5); }

.social-links {
    display: flex;
    align-items: center;
    li {
        margin-right: 20px;
        &:last-child {
            margin-right: 0; } }
    a {
        color: rgba(5, 28, 38, 0.5);
        font-size: 14px;
        transition: color 0.4s ease;
        display: block;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            transform: scaleX(0);
            border-bottom: 1px solid $black;
            transition: transform 0.4s ease; }
        &:hover {
            color: $black;
            &:after {
                transform: scaleX(1); } } } }

.add-to-favourite {
    cursor: pointer;
    cursor: url('../static/img/key-cursor.svg'), auto;
    svg {

        path {
            fill: transparent;
            stroke: $black;
            transition: 0.4s ease; } }
    &:hover {
        svg {
            path {
                fill: $black; } } } }

.btn-link {
    font-family: Baskerville;
    letter-spacing: 0.8px;
    font-size: 16px;
    color: $gold;
    position: relative;
    display: inline-block;
    font-style: italic;
    background-color: transparent;
    border: none;
    cursor: url('../static/img/key-cursor.svg'), auto;
    &:after {
        content: '';
        position: absolute;
        bottom: -7px;
        left: 0;
        width: 30px;
        border-bottom: 1px solid $gold;
        transition: width 0.4s ease; }
    &:hover {
        color: $gold;
        &:after {
            width: calc(100% + 30px); } } }

.slider {
    .slick-dots {
        display: flex;
        position: absolute;
        bottom: 50px;
        left: 50px;
        width: calc(100% - 50px);
        z-index: 1;
        li {
            margin-right: 10px;
            &:last-child {
                margin-right: 0; }
            &.slick-active {
                .slick-dot {
                    border-bottom: 2px solid #ffffff; } } } }
    .slick-dot {
        width: 15px;
        display: block;
        border-bottom: 2px solid rgba(255,255,255,0.5); } }

.favourites-remove-btn {
    cursor: pointer; }

@include media-breakpoint-down(lg) {
    .section {
        padding: 100px 0; } }

@include media-breakpoint-down(md) {
    .section-header {
        p {
            margin-top: 20px; } }
    h2, .h2 {
        font-size: 40px;
        line-height: 50px; } }

@include media-breakpoint-down(xs) {
    .social-links {
        li {
            margin-right: 10px; } }

    h2 {
        font-size: 30px;
        line-height: 40px; }

    .section-header {
        margin-bottom: 30px; }

    .section {
        padding: 75px 0; } }
