.service-section {
    position: relative;
    padding: 200px 0;
    overflow: hidden;
    &__img {
        position: absolute;
        top: 0;
        left: 70px;
        z-index: 1;
        max-height: 100%; }
    .container {
        position: relative;
        z-index: 1; }
    h2 {
        margin-bottom: 30px; }
    p {
        margin-bottom: 30px; }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 250px);
        height: 100%;
        background-image: url(../static/img/catalog-pattern.jpg);
        opacity: 0.5; }
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 235px;
        width: 55%;
        background-color: $green; } }

@media (max-width: 1750px) {
    .service-section {
        &:before {
            width: calc(100% - 150px); }
        &__content {
            position: relative;
            left: 100px; } } }

@media (max-width: 1570px) {
    .service-section {
        padding: 150px 0;
        &:after {
            height: 185px;
            width: 58%; } } }



@media (max-width: 1390px) {
    .service-section {
        &__img {
            left: -20px; } } }

@include media-breakpoint-down(lg) {
    .service-section {
        padding: 100px 0;
        &__content {
            left: 0; }
        &:before {
            width: calc(100% - 75px); }
        &:after {
            width: 65%;
            height: 130px; } } }

@include media-breakpoint-down(md) {
    .service-section__img {
        left: -27%; } }

@include media-breakpoint-down(sm) {
    .service-section {
        padding: 50px 0;
        &:before {
            width: 90%; }
        &:after {
            height: 80px; } } }
