.menu {
    position: fixed;
    background-color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease, visibility 0.4s ease;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 180px;
        width: 40%;
        height: 0;
        background-image: url(../static/img/grey-pattern.jpg);
        opacity: 0.5;
        transition: height 0.4s ease; }
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 30%;
        height: 0;
        background-color: $green;
        transition: height 0.4s ease; }
    .social-links {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%); }
    &.open {
        opacity: 1;
        visibility: visible;
        &:after {
            height: calc(100% - 160px); }
        &:before {
            height: 55%; } } }
.nav {
    position: relative;
    z-index: 1;
    ul {
        a {
            font-family: Baskerville;
            font-weight: 700;
            font-size: 50px;
            line-height: 60px;
            color: $black;
            display: block;
            transform: translateY(50px);
            transition: color 0.4s ease, transform 0.4s ease;
            &:hover {
                transform: translateX(30px);
                color: rgba(5, 28, 38, 0.5); } }
        li {
            margin: 25px 0;
            overflow: hidden; } }
    &.active {
        ul {
            a {
                transform: translateY(-5px);
                &:hover {
                    transform: translateX(30px) translateY(-5px); } } } } }

@include media-breakpoint-down(lg) {
    .nav {
        ul {
            a {
                font-size: 40px;
                line-height: 50px; }
            li {
                margin: 20px 0; } } } }

@include media-breakpoint-down(sm) {
    .menu {
        &:after {
            left: 100px; }
        &.open {
            &:after {
                height: calc(100% - 100px); }
            &:before {
                height: 45%;
                width: 20%; } } }

    .nav {
        ul {
            a {
                font-size: 35px;
                line-height: 45px; }
            li {
                margin: 15px 0; } } } }

@include media-breakpoint-down(xs) {
    .menu {
        &:after {
            left: 0;
            width: 50%; }
        .social-links {
            bottom: 30px; } }

    .nav {
        ul {
            a {
                font-size: 30px;
                line-height: 40px; }
            li {
                margin: 15px 0; } } } }
